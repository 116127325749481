body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  user-select: none; 
}

code {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.container{
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

/* padding */

.ptb-1{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ptb-2{
  padding-top: 20px;
  padding-bottom: 20px;
}
.ptb-3{
  padding-top: 30px;
  padding-bottom: 30px;
}
.ptb-4{
  padding-top: 40px;
  padding-bottom: 40px;
}
.ptb-5{
  padding-top: 50px;
  padding-bottom: 50px;
}
.ptb-6{
  padding-top: 60px;
  padding-bottom: 60px;
}
.ptb-7{
  padding-top: 70px;
  padding-bottom: 70px;
}
.ptb-8{
  padding-top: 80px;
  padding-bottom: 80px;
}
.ptb-9{
  padding-top: 90px;
  padding-bottom: 90px;
}
.ptb-10{
  padding-top: 100px;
  padding-bottom: 100px;
}
