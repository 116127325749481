/* Header Mobile Responsive */

.header {
  background: #ffffff;
  padding: 20px 0;
  height: auto;
  box-shadow: 0px 10px 10px -13px rgba(0, 0, 0, 0.1);
  position: relative;
}

.desktop-menu {
  display: none;
}

.mobile-menu {
  display: block;
}


:where(.css-dev-only-do-not-override-5wsri9).ant-drawer-right>.ant-drawer-content-wrapper {
  right: 0% !important;
}

.header .logo {
  height: 85px;
}

.header .logo a {
  height: 85px;
  display: inline-block;
}

.header .logo a img {
  height: 85px;
}

.navbar-menu ul {
  border: none;
  justify-content: flex-end;
}

.navbar-menu ul li {
  font-size: 18px;
}

.navbar-menu ul li:hover,
.navbar-menu ul li.ant-menu-item-selected,
.navbar-menu ul li.ant-menu-item-selected:after,
.navbar-menu ul li:hover:after {
  /* border-bottom-color: #00204c !important; */
  border-bottom-color: #00204c !important;
  color: #00204c !important;
}

.navbar-menu ul li.ant-menu-submenu-selected span,
.navbar-menu ul li.ant-menu-submenu-selected:after,
.navbar-menu ul li.ant-menu-submenu-active span,
.navbar-menu ul li.ant-menu-submenu-active:after {
  border-bottom-color: #00204c !important;
  color: #00204c !important;
}

.ant-menu-submenu ul li.ant-menu-item-selected span,
.ant-menu-submenu ul li.ant-menu-item-selected:after,
.ant-menu-submenu ul li.ant-menu-item-active span,
.ant-menu-submenu ul li.ant-menu-item-active:after {
  border-bottom-color: #00204c !important;
  color: #00204c !important;
}

.submenu-parent.ant-menu-item-selected {
  background-color: #00204c !important;
  color: #fff !important;
}

.submenu-parent.ant-menu-item-selected:hover .submenu-link {
  color: #fff !important;
}

.submenu-parent.ant-menu-item-active {
  background-color: #00204c !important;
  color: #fff !important;
}

.submenu-parent.ant-menu-item-active .submenu-link {
  color: #fff !important;
}


@media (min-width: 768px) {
  .desktop-menu {
    display: block;
  }

  .mobile-menu {
    display: none;
  }

}



/* Step */

.step-group {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.step {
  /* display: flex;
  justify-content: center; */
  text-align: center;
  width: 25%;
}

.step-group .line {
  align-content: center;
}

.step .step-title {
  margin-top: 20px;
  text-transform: uppercase;
  color: #000000;
  font-size: 15px;
  font-weight: normal;
}

.step.active .step-title {
  color: #00204C;
}

.step-round {
  background-color: #fff;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 1px 20px -2px rgba(0, 0, 0, 0.10);
  margin: 0 auto;
}

.step.active>.step-round {
  background-color: #00204C;
}

.step.complate>.step-round {
  background-color: #00204C;
}

.white-img {
  display: none;
}

.step.active .white-img {
  display: block;
}

.complate-img {
  display: none;
}

.step.complate .complate-img {
  display: block;
}

.step.complate .white-img {
  display: none;
}

.step.complate .black-img {
  display: none;
}

.step.active .black-img {
  display: none;
}


/* Search Form */

.search_form {
  padding-bottom: 100px;
}

.search_form .form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
}

.search_form .form-group .icon {
  margin-right: 10px;
  color: #626262;
  font-size: 18px;
  margin-right: 10px;
}

.search_form .form-group .select-box {
  width: 100%;
}

.search_form .form-group .ant-select {
  width: 100% !important;
}

.search_form .form-group .select-box .ant-select-selector {
  border: none;
  color: gray;
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10) !important;
  padding: 0;
  border-radius: 0;
}

.search_form .custom-button {
  background-color: #002855;
  border: none;
  border-radius: 50px;
  height: 50px;
  padding: 0 50px;
  font-weight: bold;
  color: white;
}

.search_form .custom-button:hover,
.search_form .custom-button:focus {
  background-color: #001f3f !important;
  color: white !important;
  border: none;
}

.search_form .ant-picker {
  padding-left: 0;
  border: none;
  padding-right: 0;
}

.search_form .form-group button.ant-btn {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  justify-content: start;
  border: none;
  color: #A5A5A5;
  box-shadow: none;
}

.search_form .contineu-btn {
  display: flex;
  align-items: center;
}


/* Filter */
.filter-item {
  margin-bottom: 20px;
}

.filter-item ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter-item ul li {
  list-style: none !important;
}

.filter-item ul li {
  margin-bottom: 10px;
  margin-right: 20px;
  background: #f5f5f5;
  padding: 5px 10px !important;
  border-radius: 10px;
  font-size: 16px;
  color: #000;
}

@media screen and (max-width:375px) {
  .filter-item ul li {
    width: 100%;
  }

  .filter-item ul li .ant-select {
    display: flex;
    justify-content: space-between;
  }

  .filter-item ul li .ant-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}


.filter-item .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.filter-item .ant-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
}

.filter-item ul li .anticon {
  font-size: 12px;
  padding-left: 5px;
}

.fillter-item-list {
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  padding-bottom: 15px;
}

.fillter-item-list h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}

.fillter-item-list label {
  display: flex;
  margin-bottom: 5px;
}

.filter-item .ant-select-selection-placeholder {
  color: #000;
}

.filter-item span.anticon.anticon-down {
  color: #000;
}

/* Hotels List > Select Hotels Page */
.hotels_list {
  padding-bottom: 60px;
}

.hotel-card {
  border-radius: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.hotel-card .image-container {
  width: 100%;
  height: 100%;
}

.hotel-card .hotel-image {
  width: 100%;
  border-radius: 50px 0 0 50px;
  height: 100%;
}

@media screen and (max-width:768px) {
  .hotel-card .hotel-image {
    width: 100%;
    border-radius: 50px 50px 0 0;
    height: 100%;
  }
}

.hotel-content {
  padding: 30px;
}

.hotel-content h2 {
  font-size: 22px;
  margin-bottom: 15px;
  line-height: 32px;
}

.hotel-content .rating {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #626262;
  line-height: 12px;
  font-size: 16px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.hotel-content .rating ul {
  line-height: 12px;
  margin-bottom: 10px;
}

.hotel-content .rating ul li {
  font-size: 12px;
  margin-inline-end: 3px !important;
}

.hotel-details .distance {
  padding-left: 10px;
  border-left: 1px solid #626262;
  margin-bottom: 10px;
}

.rating .review-text {
  margin-bottom: 10px;
}

span.distance {
  margin-bottom: 10px;
}

.hotel-description {
  font-size: 16px;
  color: #626262;
  margin-bottom: 30px;
}


.ant-modal-footer button {
  background: #002855 !important;
  border-radius: 50px !important;
}

.ant-modal-footer button:hover {
  background-color: #001f3f !important;
  color: white !important;
}

.hotel-details button {
  background: #002855;
  border-radius: 50pc;
  float: right;
  pointer-events: none;
}

.hotel-details button:hover {
  background: #001f3f !important;
  color: white !important;
}

.features {
  font-size: 14px;
  margin-top: 10px;
}

.room-tag .availability-label {
  background: rgb(0 40 85 / 60%);
  color: #ffffff;
  border-radius: 20px;
  padding: 5px 10px;
}

.price-discount {
  margin-top: 25px;
  font-size: 16px;
}

.price {
  font-size: 16px;
  color: #626262;
}

.price p {
  margin-top: 20px;
  color: #000000;
}

.price span {
  display: block;
}

.price .rate-price-per-night {
  font-size: 24px;
  color: #000000;
  font-weight: bold;
}

.buttons {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.buttons .btn-primary {
  background: #002855 !important;
  color: #ffffff;
  border-radius: 50px;
}

.buttons .btn-primary:hover {
  background: #ffffff !important;
  color: #002855 !important;
  border-color: #002855 !important;
}

.buttons .btn-primary .anticon svg {
  color: #fff;
}

.buttons .btn-primary:hover .anticon svg {
  color: #002855;
}

.buttons .btn-outline {
  background: transparent;
  border: 1px solid #002855 !important;
  color: #002855;
  border-radius: 50px;
}

.buttons .anticon svg {
  color: #002855;
}

.buttons .btn-outline:hover {
  background-color: #001f3f !important;
  color: white !important;
}

.buttons .btn-outline:hover .anticon svg {
  color: #ffffff;
}

.ant-card-body {
  padding: 0 !important;
}


/* Show Room List */

.room-image .room-img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.room-item .room-details {
  padding: 10px 0;
}

.room-item .room-details .room-quality {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}

.room-item .room-details .room-description {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #626262;
}

.room-item .room-details .room-rate {
  color: rgb(225,161,34);
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
}

.room-item .room-details .room-rate-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px
}

.personalization-tab .room-item .room-details .room-rate-footer {
  justify-content: end;
}

.room-item .room-details .room-rate-footer .room-price {
  font-size: 24px;
  font-weight: 600;
}

.room-item .room-details .room-rate-footer .btn-select {
  font-size: 16px;
  background: #002855;
  color: #fff;
  padding: 6px 24px;
  border: 1px solid #002855;
  border-radius: 40px;
  cursor: pointer;
}

.room-item .room-details .room-rate-footer .btn-select:hover {
  background: #001b3a;
  color: #fff !important;
  border: 1px solid #001b3a;
}

.room-item .room-details .radio-btn {
  margin-top: 10px;
}

.ant-dropdown-menu-title-content img {
  width: 30px;
}

/* hotel detail popup */
.hotel-details-info {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hotel-details-info .location {
  margin-bottom: 10px;
}

.hotel-details-info .rating {
  margin-bottom: 10px;
}

.hotel-details-info .rating ul {
  font-size: 12px;
}

.hotel-details button.btn {
  float: right;
  background: #002855;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #002855;
}

.hotel-details button.btn:hover {
  background: #001b3a !important;
  border: 1px solid #001b3a !important;
  color: #fff !important;
}

.hotel-overview {
  padding-bottom: 20px;
  display: inline-block;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 20px;
}

.hotel-overview h3 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 500;
}

.hotel-overview p {
  color: #626262;
  font-size: 16px;
  line-height: 26px;
}

.hotel-contact-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 20px;
}

.hotel-contact-info h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.hotel-contact-info .address span {
  display: block;
  margin-bottom: 20px;
  line-height: 26px;
  font-size: 16px;
  color: #000;
}

.room-information {
  padding-bottom: 20px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 20px;
}

.room-detail-header {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.roomContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  width: unset;
  height: unset;
  grid-gap: 10px;
}

.room-info span.anticon,
.amenitiesContainer span.anticon {
  color: #002855;
}

.room-info .room-info-name,
.amenitiesContainer .room-amenity-name {
  font-size: 15px;
  line-height: 26px;
  padding: 5px 5px;
}

.amenitiesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: flex-start;
  width: unset;
  height: unset;
  grid-gap: 10px;
}

.room-amenities .more-amenities {
  margin-top: 20px;
  color: #00204C;
}

/* room detail popup */
.hotel-room-header {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
}

.room-details-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.room-area-info {
  margin-bottom: 20px;
  font-size: 16px;
  color: #000;
}

ul.hotel-room-amenities {
  padding-left: 15px;
  margin: 0;
  margin-bottom: 20px;
}

ul.hotel-room-amenities li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #626262;
}

.hotel-room-content h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.hotel-room-content p {
  font-size: 16px;
  line-height: 26px;
  color: #626262;
}



/*  From */
.confirmation-form h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.confirmation-form p {
  font-size: 16px;
  line-height: 26px;
}

.guest-information,
.guest-address {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 40px;
  padding-bottom: 10px;
}

.confirmation-form .form-group {
  margin-bottom: 30px;
}

.confirmation-form .form-group .form-label {
  position: relative;
  top: 10px;
  z-index: 9;
  background: #fff;
  padding: 10px;
  left: 30px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.confirmation-form .form-group input,
.confirmation-form .form-group textarea {
  border-radius: 50px;
  padding: 10px 35px;
  font-size: 16px;
}

.confirmation-form .form-group input {
  height: 54px;
}

.confirmation-form .form-group textarea {
  height: auto;
}


.confirmation-form form .form_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .confirmation-form form hr {
  border: none;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  height: 1px;
}

.confirmation-form .ant-form-item {
  width: 48%;
} */

.confirmation-form .ant-form-item-label {
  position: relative;
  top: 10px;
  z-index: 9;
  background: #fff;
  padding: 10px 10px 0 10px !important;
  left: 30px;
  color: #000;
  width: fit-content;
}

.confirmation-form .ant-form-item-label label {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 16px;
}

.confirmation-form .ant-form-item-control input {
  border-radius: 50px;
  padding: 10px 35px;
  font-size: 16px;
}

.confirmation-form .ant-input-number {
  width: 100%;
  border-radius: 50px;
}

.confirmation-form .ant-input-number-handler-wrap {
  display: none;
}


.confirmation-form .btn-book-stay {
  background: #00204C;
  color: #fff;
  border: 1px solid #00204C;
  padding: 24px 30px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.confirmation-form .btn-book-stay:hover {
  background: #001b3a !important;
  border: 1px solid #001b3a !important;
  color: #fff !important;
}

#confirmation_form .ant-form-item{
  width: 100%;
  margin-top: 30px;
}
#confirmation_form .ant-form-item-row{
  position: relative;
}
#confirmation_form .ant-form-item-label {
  position: absolute;
  top: -18px;
  z-index: 9;
  background: #fff;
  padding: 0px 0px 0 10px !important;
  left: 35px;
  color: #000;
  width: fit-content;
}
#confirmation_form .ant-form-item-label label {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 0;
}
#confirmation_form input,
#confirmation_form textarea {
  border-radius: 50px;
  padding: 12px 35px;
  font-size: 16px;
}
#confirmation_form hr{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-top: 20px;
  margin-bottom: 20px;
}

#confirmation_form .ant-checkbox-group{
display: flex;
margin-top: 20px;
gap: 15px 0px;
}
#confirmation_form .ant-checkbox-group label{
  width: 48%;
}
 
#confirmation_form .roomrequest .ant-form-item-label {
  position: absolute;
  top: -18px;
  z-index: 9;
  background: #fff;
  padding: 0px 0px 0 10px !important;
  left: 0px;
  color: #000;
  width: fit-content;
}





.room-preference-option {
  margin-bottom: 40px;
}

.room-preference-option h4 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.room-preference-option .room-preference-option-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
}

.room-preference-option .room-preference-option-list label {
  font-size: 16px;
  width: 50%;
}

@media (max-width:568px) {
  .room-preference-option .room-preference-option-list label {
    font-size: 16px;
    width: 100%;
  }
}



.room-temperature,
.payment-information {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 40px;
  padding-bottom: 10px;
}

.room-temperature-option {
  margin-bottom: 20px;
}

.room-temperature-option-list .ant-radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  grid-gap: 20px;
}

.room-temperature-option-list .ant-radio-group label {
  font-size: 16px;
}


/** Step 4 */
.personalization-tab {
  background: #f5f5f5;
  padding: 20px 13px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.personalization-tab .personalization-tab-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  overflow: auto;
}

.personalization-tab .personalization-tab-list::-webkit-scrollbar {
  display: none;
}


.personalization-tab .personalization-tab-list .personalization-tab-item {
  background: #ffffff;
  padding: 8px 18px;
  border: 1px solid rgb(0 0 0 / 10%);
  margin-right: 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  transition: all 0.5s ease;
  cursor: pointer;
  align-items: center;
}

.personalization-tab .personalization-tab-list .personalization-tab-item img {
  width: 30px;
  margin-right: 10px;
}

.personalization-tab .personalization-tab-list .personalization-tab-item:hover,
.personalization-tab .personalization-tab-list .personalization-tab-item.active {
  background: #00204C;
  color: #ffffff;
}

.personalization-tab .personalization-tab-list .personalization-tab-item:hover img,
.personalization-tab .personalization-tab-list .personalization-tab-item.active img {
  filter: invert(1);
}

.personalization-tab-item:hover,
.personalization-tab-item.active {
  background: #00204C !important;
  color: #ffffff !important;
}

.personalization-tab-item:hover img,
.personalization-tab-item.active img {
  filter: invert(1);
}

.personalization-tab-item.more-tab .anticon {
  margin-left: 7px;
}


/*Dining*/
.select-date-time-option {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.select-date-time-option .select-date {
  margin-right: 10px;
  height: 60px;
}

.select-date-time-option label {
  display: block;
  margin-bottom: 5px;
}

.select-date-time-option .ant-picker {
  display: block;
  width: 105px;
}

.select-date-time-option .select-time {
  height: 60px;
}

.select-date-time-option .select-time .ant-form-item {
  display: block;
  width: 115px;
}

.room-details a.ant-dropdown-trigger {
  border: 1px solid rgb(0 0 0 / 10%);
  padding: 8px 10px;
  display: block;
  border-radius: 5px;
  color: #626262;
  margin-bottom: 10px;
}

.room-details .ant-space {
  display: flex;
  justify-content: space-between;
}

.room-details .ant-dropdown-menu-item-extra {
  font-size: 15px !important;
  font-weight: 500;
  color: #000 !important;
}

.btn-add-stay {
  background: #002855;
  color: #fff;
  padding: 9px 19px;
  border: 1px solid #002855;
  border-radius: 40px;
  font-size: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.btn-add-stay .hide-text {
  transition: all 0.5s ease;
  display: none;
}

.btn-add-stay:hover .hide-text {
  display: block;
  padding-right: 10px;
}

.btn-add-stay:hover {
  background: #001b3a !important;
  border: 1px solid #001b3a !important;
  color: #fff !important;
  width: 100%;
}


/***** Cart *****/
.hotel-cart {
  background: #ffffff;
  box-shadow: 0px 1px 20px -2px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  margin-left: 30px;
}

.hotel-cart .cart-top {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.hotel-cart .cart-middle {
  padding: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.hotel-cart .cart-middle .cart-itme {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  padding-right: 90px;
  font-size: 16px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.hotel-cart .cart-middle .cart-itme .room-price {
  position: absolute;
  right: 20px;
  font-size: 22px;
  font-weight: 600;
}

.hotel-cart .cart-middle .cart-itme .cart-room-name {
  font-size: 17px;
  margin-bottom: 10px;
  color: #000000;
}

.hotel-cart .cart-middle .cart-itme .hotel-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.hotel-cart .cart-middle .cart-itme .room-booking-date {
  margin-bottom: 10px;
}

.hotel-cart .cart-middle .cart-itme .cart-remove {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #00204c;
  font-size: 14px;
}

.hotel-cart .cart-middle .cart-total-price {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.hotel-cart .cart-middle .cart-total-price li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
  color: #626262;
}

.hotel-cart .cart-middle .cart-total-price li span.item-price {
  color: #000;
  font-weight: 600;
}

.cart-bottom {
  background: #002855;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cart-bottom .cart-total-cost label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.cart-bottom .cart-total-cost span {
  font-size: 20px;
  font-weight: 600;
}

.cart-bottom .cart-btn {
  padding: 20px 30px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #ffffff;
  transition: all 0.5s ease;
}

.cart-bottom .cart-btn:hover {
  background: #001b3a !important;
  color: #ffffff !important;
  border-color: #001b3a !important
}

.promo-code label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}

.promo-code input {
  display: inline-block;
  width: 68%;
  margin-right: 10px;
}

.promo-code .apply-btn {
  background: #002855;
  border: 1px solid #002855;
  color: #fff;
  padding: 10px 24px;
  border-radius: 50px;
  height: auto;
  line-height: normal;
  width: 28%;
}

.promo-code .apply-btn:hover {
  background: #001b3a !important;
  color: #ffffff !important;
  border-color: #001b3a !important
}



/*****Footer*****/
.footer {
  background: #323232;
  color: #fff;
  padding: 100px 0 70px 0;
}

.footer .footer-widget {
  margin-bottom: 30px;
}

.footer .footer-widget .footer-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer .footer-widget .footer-sub-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer .footer-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footer .footer-widget ul li {
  margin-bottom: 20px;
  font-size: 16px;
  color: #ffffff;
}

.footer .footer-widget ul li a {
  color: #ffffff;
}

.footer .footer-widget ul li a:hover {
  text-decoration: underline;
}

.footer .footer-widget ul li {
  margin-bottom: 20px;
  font-size: 16px;
  color: #ffffff;
}

.footer .footer-widget ul.sodial-icon li {
  background: #fff;
  padding: 5px;
  margin-right: 10px;
  color: #000;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.footer .footer-widget ul.sodial-icon li:hover {
  background: #00204c;
  color: #fff;
}

.footer-widget p {
  font-size: 16px;
  line-height: 26px;
}









/* Mobile Sidebar Menu */

.mobile-sidebar-menu li {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mobile-sidebar-menu ul li.ant-menu-item:hover {
  color: #fff !important;
  background-color: #00204c !important;
}

.mobile-sidebar-menu ul li.ant-menu-item-selected {
  color: #fff !important;
  background-color: #00204c !important;
}

.mobile-sidebar-menu ul li.submenu-parent-mobile:hover span a {
  color: #fff !important;
}

.mobile-sidebar-menu ul li.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #00204c !important;
  background-color: #f5f5f5 !important;
}

.mobile-sidebar-menu ul li.ant-menu-submenu-open .ant-menu-submenu-title {
  color: #00204c !important;
  background-color: #f5f5f5 !important;
}

.mobile-sidebar-menu ul li.ant-menu-submenu-active .ant-menu-submenu-title {
  color: #00204c !important;
  background-color: #f5f5f5 !important;
}


/********* Responsice Css **********/

@media (max-width:1199px) {

  /** search form */
  .search_form .custom-button {
    padding: 0 30px;
  }


}

@media (max-width:1023px) {

  .center-line img {
    width: 150px;
  }

}

@media (max-width:991px) {

  /** Step */
  .step-round {
    width: 80px;
    height: 80px;
  }

  .step .step-title {
    font-size: 14px;
  }

  .center-line img {
    width: 110px;
  }

  .search_form {
    padding-bottom: 70px;
  }

  .footer {
    padding: 70px 0 40px 0;
  }

  .hotel-cart {
    margin-left: 0;
  }


}

@media (max-width:767px) {

  .react-multiple-carousel__arrow {
    min-width: 30px !important;
    min-height: 30px !important;
  }

  .header {
    padding: 10px 0;
  }

  .header .logo {
    height: 50px;
  }

  .header .logo a {
    height: 50px;
    display: inline-block;
  }

  .header .logo a img {
    height: 50px;
  }

  .mobile-menu svg {
    font-size: 26px !important;
  }



  /** Step */
  .step-round {
    width: 60px;
    height: 60px;
  }

  .step .step-title {
    display: none;
  }

  .step-round img {
    width: 30px;
  }

  .center-line img {
    width: 80px;
  }

  .hotel-content {
    padding: 20px 10px;
  }

  .hotel-details-info {
    display: block;
  }

  .hotel-details button {
    float: none;
  }

  .hotel-overview {
    margin-top: 20px;
  }

  .roomContainer,
  .amenitiesContainer {
    grid-template-columns: 1fr;
  }

  .room-preference-option .room-preference-option-list {
    grid-template-columns: 1fr;
  }

  .search_form {
    padding-bottom: 50px;
  }

  .footer {
    padding: 50px 0 20px 0;
  }


}

@media (max-width:499px) {

  /** Step */
  .step-round {
    width: 50px;
    height: 50px;
  }

  .step-round img {
    width: 25px;
  }

  .center-line img {
    width: 50px;
  }


  .hotel-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .buttons {
    margin-top: 10px;
  }

  .buttons .btn-outline {
    font-size: 14px;
    padding: 10px;
  }


}

@media (max-width:320px) {

  /** Step */
  .step-round {
    width: 40px;
    height: 40px;
  }

  .step-round img {
    width: 20px;
  }

  .center-line img {
    width: 40px;
  }


}






/* Confirmation page back button */

.back_btn {
  padding: 10px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.back_btn button {
  padding: 5px;
  border-radius: 50px;
  border: none;
  margin-right: 10px;
}

.back_btn button svg {
  cursor: pointer;
}


/* Form Submit after Loading css */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #00204c;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/* Tab Data CSS step 3 */

.react-multi-carousel-track {
  margin: auto !important;
}



.btn-find-hotel {
  background: #00204C;
  color: #fff;
}

.btn-find-hotel:hover {
  background: #001b3a !important;
  color: #fff !important;
}


.ant-picker-cell{
  color: #000 !important;
}
.ant-picker-cell.ant-picker-cell-disabled{
  color: #aaa !important;
}